import { get, cloneDeep } from 'lodash';
import {
  FETCH_ALL_CLASSES,
  FETCH_ALL_TEACHERS,
  FETCH_CHILDREN_ASSIGN_CLASS,
  FETCH_CHILDREN_DATA,
  FETCH_ALL_CHILD,
  FETCH_CURRENT_CHILDID,
  FETCH_PAST_CHILDREN,
  FETCH_PAST_CHILDREN_SEARCH,
  FETCH_REQ_DOCS,
  FETCH_UPLOAD_DOC_URL,
  FETCH_ACTIVITY_LOG,
  FETCH_DOCUMENT_BY_CATEGORY,
  FETCH_UPLOADED_DOCS,
  FETCH_ALL_DOCUMENT_TYPE,
  FETCH_DOCUMENT_TYPE_TO_UPLOAD,
  CLEAR_FETCH_ALL_CHILD,
  CLEAR_ALL_CLASSES,
  FETCH_ALL_GUARDIAN,
  SET_ASSIGN_OR_PROMOTE_DETAIL,
  CLEAR_ASSIGN_OR_PROMOTE_DETAIL,
  FETCH_CLASS_DETAIL,
  CLEAR_CLASS_DETAIL,
  FETCH_FORM2_COUNT,
  FETCH_FORM3_COUNT,
  CLEAR_CURRENT_CHILD,
  FIND_ALL_PROGRAMS,
  FETCH_UPDATED_CHILD_PARTICULAR,
  FETCH_CLASS_ACTIVITIES,
  FETCH_CLASS_ACTIVITY_TAGS,
  FETCH_CLASS_ACTIVITY_STICKERS,
  FETCH_CLASS_RESOURCES,
  FETCH_LEARNING_GOALS_OPTIONS_LIST,
  FETCH_FEE_TIER_FOR_CHILD,
  FETCH_EMERGENCY_CONTACT_INFO_ERROR,
  FETCH_EMERGENCY_CONTACT_INFO_SUCCESS,
  FETCH_EMERGENCY_CONTACT_INFO,
  CLEAR_EMERGENCY_CONTACT_INFO,
  SET_UPLOAD_IMAGES,
  FETCH_CHILD_HEALTH_INFO,
  FETCH_CHILD_HEALTH_INFO_SUCCESS,
  FETCH_CHILD_HEALTH_INFO_ERROR,
  UPDATE_CHILD_HEALTH_INFO_REQUEST,
  UPDATE_CHILD_HEALTH_INFO_SUCCESS,
  UPDATE_CHILD_HEALTH_INFO_ERROR,
  FETCH_UPLOAD_DOCS_REQUEST,
  FETCH_UPLOAD_DOCS_SUCCESS,
  FETCH_UPLOAD_DOCS_ERROR,
  REMOVE_ENROLLED_CHILD_DOC_REQUEST,
  REMOVE_ENROLLED_CHILD_DOC_SUCCESS,
  REMOVE_ENROLLED_CHILD_DOC_ERROR,
  UPDATE_DOCS_UPLOADED_BY_TYPE_REQUEST,
  UPDATE_DOCS_UPLOADED_BY_TYPE_SUCCESS,
  UPDATE_DOCS_UPLOADED_BY_TYPE_ERROR,
  REMOVE_ENROLLED_CHILD_DOC_TYPE_REQUEST,
  REMOVE_ENROLLED_CHILD_DOC_TYPE_SUCCESS,
  REMOVE_ENROLLED_CHILD_DOC_TYPE_ERROR,
  FETCH_APPLICABLE_FEE_TIER_FOR_CHILD,
  FETCH_CHILD_FEE_TIER_FOR_CHILD,
  FETCH_UPCOMING_FEE_TIER_FOR_CHILD,
  GET_MARKETING_CONSENT_BY_PARENT_REQUEST,
  GET_MARKETING_CONSENT_BY_PARENT_SUCCESS,
  GET_MARKETING_CONSENT_BY_PARENT_ERROR,
  FETCH_ALL_GUARDIAN_SEARCH,
  FETCH_CSS_PROGRAMMES,
  FETCH_CSS_PROGRAMMES_PILLAR_NAMES,
} from '../actions/actionTypes';
import { getChildEnrolmentStatus } from '../../utils';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

export const getAllUploadingAssets = (state = {}, action) => {
  if (action.type === SET_UPLOAD_IMAGES) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return state;
  }
  return state;
};

const modelChildData = (data, viewingUser) => {
  const modelFunc = eachChild => {
    const tempChild = cloneDeep(eachChild);
    const upcomingLevel = get(eachChild, 'upcomingLevel');
    const upcomingClass = get(eachChild, 'upcomingClass');
    const currentLevel = get(eachChild, 'currentLevel');
    const currentClass = get(eachChild, 'currentClass');
    const childLevels = get(eachChild, 'childLevels.data', []);
    const latestLevels = childLevels.length
      ? childLevels[childLevels.length - 1]
      : null;

    const childEnrolmentStatus = get(viewingUser, 'userAccessControls.data')
      ? getChildEnrolmentStatus(
          currentLevel,
          upcomingLevel,
          get(eachChild, 'enrolmentDate'),
          get(viewingUser, 'userAccessControls.data'),
          latestLevels
        )
      : null;

    // Handling show of upcoming level of newly enrolled as current
    // If upcoming level is there and current level is not there, it means the child is newly enrolled
    if (!currentLevel && upcomingLevel) {
      tempChild.currentLevel = upcomingLevel;
      tempChild.upcomingLevel = null;
    }

    // Handling show of upcoming class of newly enrolled as current
    // If upcoming class is there and current class is not there, it means the child is newly enrolled
    if (!currentClass && upcomingClass) {
      tempChild.currentClass = upcomingClass;
      tempChild.upcomingClass = null;
    }

    if (
      get(upcomingLevel, 'centre.ID') &&
      get(currentLevel, 'centre.ID') &&
      get(upcomingLevel, 'centre.ID') !== get(currentLevel, 'centre.ID')
    ) {
      tempChild.upcomingClass = null;
      tempChild.upcomingLevel = null;
    }

    return {
      ...tempChild,
      childEnrolmentStatus,
    };
  };

  if (!Array.isArray(data)) {
    return modelFunc(data);
  }

  const modeledData = [];
  data.forEach(eachChild => {
    modeledData.push(modelFunc(eachChild));
  });
  return modeledData;
};

/**
 *
 * Example data
 * {
 *  data {
 *    findAllGuardian {
 *      data: []
 *    }
 *  }
 * }
 */
export const allGuardiansDetails = (state = {}, action) => {
  if (action.type === FETCH_ALL_GUARDIAN) {
    const data = get(action, 'value.data.data.findAllGuardian.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const allGuardiansSearchDetails = (state = {}, action) => {
  if (action.type === FETCH_ALL_GUARDIAN_SEARCH) {
    const data = get(action, 'value.data.data.findAllGuardian.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 * Example data
 * getDocumentTypeByDocumentCategory
 */

export const documentByCategory = (state = {}, action) => {
  if (action.type === FETCH_DOCUMENT_BY_CATEGORY) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 * Example data
 * {
 *  data {
 *    documentTypeConfigsByFkDocumentTypeConfig: {
 *      data [{
 *        ID: 1
 *        code: "enrolment_documents"
 *        fkDocumentTypeConfig: null
 *        label: "Enrollment"
 *      }]
 *    }
 *  }
 * }
 */

export const documentTypeConfig = (state = {}, action) => {
  if (action.type === FETCH_ALL_DOCUMENT_TYPE) {
    const data = get(
      action,
      'value.data.documentTypeConfigsByFkDocumentTypeConfig.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "documentTypeConfigsByFkDocumentTypeConfig": {
 *      "data": [
 *        {
 *          "fkSchool": null,
 *          "code": "enrolment_documents",
 *          "label": "Enrollment",
 *          "fkDocumentTypeConfig": null,
 *          "ID": 1
 *        },
 *      ]
 *    }
 *  }
 *}
 */
export const documentTypeForUpload = (state = {}, action) => {
  if (action.type === FETCH_DOCUMENT_TYPE_TO_UPLOAD) {
    const data = get(
      action,
      'value.data.documentTypeConfigsByFkDocumentTypeConfig.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "childByID": {
 *      "firstname": "Sara-Ann",
 *      "lastname": "Arabad",
 *      "ID": 878,
 *      "fkSubsidy": null,
 *      "race": "indian",
 *      "nationality": "citizen",
 *      "gender": null,
 *      "birthCertificate": "T1902720H",
 *      "dateOfBirth": "2019-01-30 00:00:00",
 *      "active": true,
 *      "supportScheme": null,
 *      "createdAt": "2019-12-02 14:55:37",
 *      "updatedAt": "2020-02-25 14:41:45",
 *      "imageKey": null,
 *      "enrolmentDate": null,
 *      "childCurrentEstimatedPromotionDate": null,
 *      "nationalityUpdateEffectiveFrom": null,
 *      "programTypeUpdateEffectiveFrom": null,
 *      "supportSchemaUpdateEffectiveFrom": null,
 *      "householdIncomeRange": "9000",
 *      "numFamilyMembers": 0,
 *      "bankAccounts": {
 *        "data": []
 *      },
 *      "enquiries": {
 *        "data": [
 *          {
 *            "documentStatus": "pending",
 *            "documentNotificationSent": false,
 *            "applicationSubmitted": false,
 *            "enrolmentStartDate": null,
 *            "skipCentreVisit": false,
 *            "skipTrial": false,
 *            "fkEnquiryState": "waiting_list",
 *            "visits": {
 *              "data": [
 *                {
 *                  "ID": 304,
 *                  "fkUser": 1368,
 *                  "status": "completed",
 *                  "fkEnquiry": 302,
 *                  "fkCentre": 2,
 *                  "visitTo": "2019-12-07 12:30:00",
 *                  "visitFrom": "2019-12-07 12:00:00",
 *                  "visitType": "centre_visit",
 *                  "trialPeriodFee": null,
 *                  "trialPeriodPaymentStatus": null,
 *                  "comment": "-",
 *                  "centre": {
 *                    "ID": 2,
 *                    "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *                  }
 *                }
 *              ]
 *            },
 *            "child": {
 *              "firstname": "Sara-Ann",
 *              "lastname": "Arabad",
 *              "dateOfBirth": "2019-01-30 00:00:00",
 *              "enquiries": {
 *                "data": [
 *                  {
 *                    "enrollmentPlan": "2021-01-01 00:00:00",
 *                    "fkEnquiryState": "waiting_list",
 *                    "centre": {
 *                      "ID": 2,
 *                      "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic"
 *                    }
 *                  }
 *                ]
 *              }
 *            },
 *            "centre": {
 *              "label": "The Caterpillar's Cove @ Ngee Ann Polytechnic",
 *              "ID": 2
 *            },
 *            "waitlistTags": {
 *              "data": []
 *            },
 *            "ID": 302,
 *            "firstname": "Elfy",
 *            "lastname": "Lee",
 *            "childFirstname": "Sara-Ann",
 *            "childLastname": "Arabad",
 *            "active": true,
 *            "waitlistStatus": true,
 *            "applicationSubmission": null,
 *            "enrollmentPlan": "2021-01-01 00:00:00",
 *            "vacancyAvailable": true,
 *            "privateVacancyAvailable": true,
 *            "isTransferEnquiry": false,
 *            "level": {
 *              "label": "Junior Toddlers",
 *              "ID": 17
 *            },
 *            "program": {
 *              "ID": 10,
 *              "label": "Full Day"
 *            },
 *            "householdIncomeRange": "9000",
 *            "admissionStatus": {
 *              "ID": 100,
 *              "label": "Enquire",
 *              "stateCode": "enquire",
 *              "enquiryFrontendState": null
 *            }
 *          }
 *        ]
 *      },
 *      "withdrawalDraftItems": {
 *        "data": []
 *      },
 *      "withdrawals": {
 *        "data": []
 *      },
 *      "mainApplicant": {
 *        "data": [
 *          {
 *            "ID": 1819,
 *            "fkUser": 1368,
 *            "fkChild": 878,
 *            "fkRelation": null,
 *            "user": {
 *              "ID": 1368,
 *              "firstname": "Elfy",
 *              "lastname": "Lee",
 *              "homePhone": "40001368",
 *              "homePhoneCountryCode": "+65000",
 *              "mobilePhone": "40001368",
 *              "email": "sn2dummy+40001368@gmail.com",
 *              "birthdate": null,
 *              "gender": null,
 *              "parent": {
 *                "firstname": "Elfy",
 *                "lastname": "Lee",
 *                "gender": null,
 *                "email": "sn2dummy+40001368@gmail.com",
 *                "birthdate": null,
 *                "identificationNo": null,
 *                "nationality": null,
 *                "race": null,
 *                "monthlyIncome": "9000",
 *                "highestQualification": null,
 *                "maritalStatus": null
 *              },
 *              "addresses": {
 *                "data": []
 *              }
 *            },
 *            "parent": {
 *              "ID": 1426,
 *              "firstname": "Elfy",
 *              "lastname": "Lee",
 *              "mobilePhoneCountryCode": "+65000",
 *              "mobilePhone": "40001368",
 *              "email": "sn2dummy+40001368@gmail.com",
 *              "birthdate": null,
 *              "gender": null,
 *              "identificationNo": null,
 *              "isParent": null,
 *              "monthlyIncome": "9000",
 *              "nationality": null,
 *              "highestQualification": null,
 *              "maritalStatus": null,
 *              "workingStatus": null,
 *              "occupationalTitle": null,
 *              "isEmployed": null,
 *              "selfEmployedMonthlyIncome": null,
 *              "nationalityUpdateEffectiveFrom": null,
 *              "maritalStatusUpdateEffectiveFrom": null,
 *              "workingStatusUpdateEffectiveFrom": null,
 *              "reasonOfUnemployment": null,
 *              "addresses": {
 *                "data": []
 *              },
 *              "createdAt": "2019-12-02 14:55:37",
 *              "hasNoa": null,
 *              "hasCpf": null,
 *              "isRecentlyEmployed": null,
 *              "commencementDate": null
 *            }
 *          }
 *        ]
 *      },
 *      "secondApplicant": {
 *        "data": []
 *      },
 *      "currentLevel": null,
 *      "upcomingLevel": null,
 *      "childLevels": {
 *        "data": []
 *      },
 *      "closestLevel": null,
 *      "currentClass": null,
 *      "upcomingClass": null,
 *      "childAuthorisedPersonals": {
 *        "data": []
 *      }
 *    }
 *  }
 *}
 */

export const currentChildIdDetails = (state = {}, action) => {
  if (action.type === FETCH_CURRENT_CHILDID) {
    const data = get(action, 'value.data.data.childByID');
    if (data) {
      const viewingUser = get(action, 'value.data.data.viewingUser');
      return Object.assign({}, state, {
        ...action.value,
        data: modelChildData(data, viewingUser),
      });
    }
    return Object.assign({}, action.value);
  }

  if (action.type === CLEAR_CURRENT_CHILD) {
    return Object.assign({}, state, {
      ...action.value,
      data: null,
    });
  }
  return state;
};

/**
 *
 * Example data
 * updated child
 */

export const getUpdatedChild = (state = {}, action) => {
  if (action.type === FETCH_UPDATED_CHILD_PARTICULAR) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getPastChildrenData": {
 *      "data": [
 *        {
 *          "child": {
 *            "ID": 22,
 *            "firstname": "",
 *            "lastname": "Shane Chong Kai Le (Zhong Kaile)",
 *            "dateOfBirth": "2014-06-26 00:00:00",
 *            "birthCertificate": "T1419382G",
 *            "enrolmentDate": "2016-04-01 00:00:00",
 *            "imageKey": null,
 *            "upcomingClass": null
 *          },
 *          "centre": {
 *            "ID": 1,
 *            "label": "The Caterpillar's Cove @ Jurong East"
 *          },
 *          "enrolmentDate": "2016-04-01 00:00:00",
 *          "withdrawnDate": "2020-11-30 23:59:59"
 *        },
 *      ]
 *    }
 *  }
 *}
 */
export const pastChildDetails = (state = {}, action) => {
  if (action.type === FETCH_PAST_CHILDREN) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const pastChildSearchResults = (state = {}, action) => {
  if (action.type === FETCH_PAST_CHILDREN_SEARCH) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * getData
 */
export const getUploadedFiles = (state = {}, action) => {
  if (action.type === FETCH_UPLOADED_DOCS) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getAllClassInfo": {
 *      "totalCount": 15,
 *      "data": [
 *        {
 *          "ID": 2,
 *          "label": "Infants Class",
 *          "description": "Infants Class",
 *          "level": {
 *            "ID": 16,
 *            "label": "Infant",
 *            "nextLevel": {
 *              "ID": 17,
 *              "label": "Junior Toddlers"
 *            }
 *          },
 *          "centre": {
 *            "ID": 1,
 *            "label": "The Caterpillar's Cove @ Jurong East"
 *          },
 *          "currentChildClasses": {
 *            "totalCount": 5
 *          },
 *          "classTeachers": {
 *            "data": [
 *              {
 *                "ID": 5,
 *                "user": {
 *                  "ID": 868,
 *                  "firstname": "Qiuhua",
 *                  "lastname": "Lin"
 *                }
 *              },
 *              {
 *                "ID": 6,
 *                "user": {
 *                  "ID": 865,
 *                  "firstname": "Nurul Elma",
 *                  "lastname": "Rahmat"
 *                }
 *              },
 *              {
 *                "ID": 8,
 *                "user": {
 *                  "ID": 903,
 *                  "firstname": "Siti Rohani",
 *                  "lastname": "Sudin"
 *                }
 *              },
 *              {
 *                "ID": 9,
 *                "user": {
 *                  "ID": 880,
 *                  "firstname": "Ping",
 *                  "lastname": "Xu"
 *                }
 *              }
 *            ]
 *          },
 *          "upcomingChildClasses": {
 *            "totalCount": 0
 *          }
 *        },
 *      ]
 *    }
 *  }
 *}
 */

export const listAllClasses = (state = {}, action) => {
  if (action.type === FETCH_ALL_CLASSES) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, state, action.value);
  }

  if (action.type === CLEAR_ALL_CLASSES) {
    return Object.assign({}, state, {
      data: {},
    });
  }
  return state;
};

/**
 *
 * Example data
 * not arrya getChildrenToAssignToClass
 */

export const assignChildrenToClass = (state = {}, action) => {
  if (action.type === FETCH_CHILDREN_ASSIGN_CLASS) {
    const data = get(action, 'value.data.getChildrenToAssignToClass.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: modelChildData(data),
        totalCount: get(
          action,
          'value.data.getChildrenToAssignToClass.totalCount'
        ),
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "getTeacherListOnInput": {
 *      "totalCount": 7,
 *      "data": [
 *        {
 *          "user": {
 *            "ID": 852,
 *            "firstname": "Nur Amalina",
 *            "lastname": "Kamis"
 *          }
 *        },
 *      ]
 *    }
 *  }
 * }
 */

export const listAllTeachers = (state = {}, action) => {
  if (action.type === FETCH_ALL_TEACHERS) {
    const data = get(action, 'value.data.getTeacherListOnInput.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const getChildrenData = (state = {}, action) => {
  if (action.type === FETCH_CHILDREN_DATA) {
    let data = null;
    if (action.key === 'childByID') {
      data = [get(action, `value.data.data.${action.key}`)];
    } else {
      data = get(action, `value.data.data.${action.key}.data`);
    }

    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: modelChildData(data),
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const fetchAllChild = (state = {}, action) => {
  if (action.type === FETCH_ALL_CHILD) {
    const data = get(action, 'value.data.data.findAllChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: action.value.data.data.findAllChild.data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  if (action.type === CLEAR_FETCH_ALL_CHILD) {
    return Object.assign({}, state, {
      data: {},
    });
  }
  return state;
};

export const listAllReqDocs = (state = {}, action) => {
  if (action.type === FETCH_REQ_DOCS) {
    const data = get(action, 'value.data.data.listRequiredDocuments');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const emergencyContactDetails = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_EMERGENCY_CONTACT_INFO:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_EMERGENCY_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getEmergencyContact'),
      };
    case FETCH_EMERGENCY_CONTACT_INFO_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case CLEAR_EMERGENCY_CONTACT_INFO:
      return {
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
};

/**
 * Example data
 * {
 *  data {
 *    getUploadedDocument {
 *      data [{
 *        ID: 1020,
 *        documentTags: {
 *            data [{
 *              fkDocumentType: 2206,
 *              documentType: {
 *                createdAt: "2020-09-23 10:52:50",
 *                label: "Enrollment supporting documents"
 *                user: null
 *              }
 *            }]
 *         }
 *        filename: "Roxanne_immunization report_1.jpg"
 *        fkChild: 67
 *        uRL: "upload/document/enquiry/67/Roxanne_immunization-report_1-20200923-1600829569764792919-7826.jpg"
 *        updatedAt: "2020-09-23 10:52:50"
 *      }]
 *    }
 *  }
 * }
 */

export const listAllUploadedDocs = (state = {}, action) => {
  switch (action.type) {
    case FETCH_UPLOAD_DOCS_REQUEST:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_UPLOAD_DOCS_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_UPLOAD_DOCS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case REMOVE_ENROLLED_CHILD_DOC_REQUEST:
      return {
        ...state,
        ...action.value,
      };
    case REMOVE_ENROLLED_CHILD_DOC_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: get(state, 'data', []).map(item => {
          return {
            ...item,
            documentTags: {
              data: get(item, 'documentTags.data', []).filter(
                doc => doc.document.ID !== get(action, 'value.data.documentID')
              ),
            },
          };
        }),
      };
    case REMOVE_ENROLLED_CHILD_DOC_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case UPDATE_DOCS_UPLOADED_BY_TYPE_REQUEST:
      return {
        ...state,
        ...action.value,
      };
    case UPDATE_DOCS_UPLOADED_BY_TYPE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: [
          ...state.data.filter(
            item =>
              item.fkDocumentTypeConfig !==
              get(action, 'value.data.0.fkDocumentTypeConfig')
          ),
          ...action.value.data,
        ],
      };
    case UPDATE_DOCS_UPLOADED_BY_TYPE_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case REMOVE_ENROLLED_CHILD_DOC_TYPE_REQUEST:
      return {
        ...state,
        ...action.value,
      };
    case REMOVE_ENROLLED_CHILD_DOC_TYPE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: state.data.filter(
          item => item.ID !== get(action, 'value.data.documentTypeID')
        ),
      };
    case REMOVE_ENROLLED_CHILD_DOC_TYPE_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const listUploadedUrl = (state = {}, action) => {
  if (action.type === FETCH_UPLOAD_DOC_URL) {
    const data = get(action, 'value.data.data.getEnrolmentDocumentURL');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * {
 *   "data": {
 *     "findAllChildActivityLog": {
 *       "totalCount": 0,
 *       "data": [{
 *         "ID": 1,
 *         "mode": "other",
 *         "action": "Registration for EUNICE has been created",
 *         "updatedAt": "2020-11-24 16:17:56",
 *         "code": {
 *           "label": "create_registration",
 *           "description": "New Registration"
 *         },
 *         "user": {
 *           "firstname": "Erina",
 *           "lastname": "Nakiri"
 *         },
 *         "remarks": null
 *       },]
 *     }
 *   }
 * }
 */

export const listAllLogs = (state = {}, action) => {
  if (action.type === FETCH_ACTIVITY_LOG) {
    const data = get(action, 'value.data.data.findAllChildActivityLog');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const assignOrPromoteDetail = (state = {}, action) => {
  if (action.type === SET_ASSIGN_OR_PROMOTE_DETAIL) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: {
          ...state.data,
          ...data,
        },
      });
    }
    return Object.assign({}, state, action.value);
  }

  if (action.type === CLEAR_ASSIGN_OR_PROMOTE_DETAIL) {
    return Object.assign({}, state, {
      data: {
        classId: null,
        assignOnly: false,
        childrenList: [],
      },
    });
  }

  return Object.assign({}, state, {
    data: {
      classId: null,
      assignOnly: false,
      childrenList: [],
      ...state.data,
    },
  });
};

/**
 * EXAMPLE Data
 * {
  "data": {
    "getAllClassInfo": {
      "data": [
        {
          "ID": 2,
          "label": "Infants Class",
          "description": "Infants Class",
          "level": {
            "ID": 16,
            "label": "Infant",
            "nextLevel": {
              "ID": 17,
              "label": "Junior Toddlers"
            }
          },
          "centre": {
            "ID": 1,
            "label": "The Caterpillar's Cove @ Jurong East"
          },
          "coverPhotoKey": null,
          "profilePhotoKey": null,
          "currentChildClasses": {
            "totalCount": 5,
            "data": [
              {
                "child": {
                  "ID": 57,
                  "firstname": "",
                  "lastname": "Seah Si Ying",
                  "dateOfBirth": "2019-07-29 00:00:00",
                  "birthCertificate": "T1921957C",
                  "enrolmentDate": "2019-12-01 00:00:00",
                  "imageKey": "upload/child/57/SI-YING-20200805-1596627363882703114-5951.jpg",
                  "upcomingClass": null
                }
              },
              {
                "child": {
                  "ID": 602,
                  "firstname": "",
                  "lastname": "Zou Muchen @ Zou Mu Chen Elias",
                  "dateOfBirth": "2019-12-22 00:00:00",
                  "birthCertificate": "G3585479M",
                  "enrolmentDate": "2020-08-01 00:00:00",
                  "imageKey": "upload/child/602/ELIAS-20200806-1596720724567105275-4917.jpg",
                  "upcomingClass": {
                    "class": {
                      "ID": 5,
                      "label": "Junior Toddlers Class",
                      "level": {
                        "label": "Junior Toddlers"
                      },
                      "centre": {
                        "ID": 1,
                        "label": "The Caterpillar's Cove @ Jurong East"
                      }
                    },
                    "from": "2021-07-01 00:00:00"
                  }
                }
              },
              {
                "child": {
                  "ID": 1277,
                  "firstname": "ChildWith1Parentd0g2b",
                  "lastname": "Lee",
                  "dateOfBirth": "2020-01-01 00:00:00",
                  "birthCertificate": "T8949368K",
                  "enrolmentDate": "2020-12-01 00:00:00",
                  "imageKey": null,
                  "upcomingClass": null
                }
              },
              {
                "child": {
                  "ID": 1179,
                  "firstname": "EMMA TEO",
                  "lastname": "YU MAN ",
                  "dateOfBirth": "2019-12-14 00:00:00",
                  "birthCertificate": "T1937616D",
                  "enrolmentDate": "2020-09-01 00:00:00",
                  "imageKey": "upload/child/1179/20200902_153402-20200902-1599032051453768429-4204.jpg",
                  "upcomingClass": null
                }
              },
              {
                "child": {
                  "ID": 584,
                  "firstname": "Hansel ",
                  "lastname": "Tung Siang Quan",
                  "dateOfBirth": "2020-01-29 00:00:00",
                  "birthCertificate": "T2002287B",
                  "enrolmentDate": "2020-06-01 00:00:00",
                  "imageKey": "upload/child/584/138303104-20200508-1588928619735452468-8958.jpg",
                  "upcomingClass": null
                }
              }
            ]
          },
          "upcomingChildClasses": {
            "totalCount": 2,
            "data": [
              {
                "child": {
                  "ID": 1285,
                  "firstname": "ChildWith1Parent96a91",
                  "lastname": "Lee",
                  "dateOfBirth": "2020-01-01 00:00:00",
                  "birthCertificate": "T4675879K",
                  "enrolmentDate": "2020-12-01 00:00:00",
                  "imageKey": null,
                  "upcomingClass": {
                    "class": {
                      "ID": 2,
                      "label": "Infants Class",
                      "level": {
                        "label": "Infant"
                      },
                      "centre": {
                        "ID": 1,
                        "label": "The Caterpillar's Cove @ Jurong East"
                      }
                    },
                    "from": "2020-12-24 00:00:00"
                  }
                }
              },
              {
                "child": {
                  "ID": 1297,
                  "firstname": "Maria",
                  "lastname": "Cheng",
                  "dateOfBirth": "2019-09-01 00:00:00",
                  "birthCertificate": "Y0987625H",
                  "enrolmentDate": "2020-12-01 00:00:00",
                  "imageKey": "upload/child/1297/smile-20201028-1603862844658451988-9967.jpg",
                  "upcomingClass": {
                    "class": {
                      "ID": 2,
                      "label": "Infants Class",
                      "level": {
                        "label": "Infant"
                      },
                      "centre": {
                        "ID": 1,
                        "label": "The Caterpillar's Cove @ Jurong East"
                      }
                    },
                    "from": "2020-12-24 00:00:00"
                  }
                }
              }
            ]
          },
          "classTeachers": {
            "data": [
              {
                "ID": 5,
                "user": {
                  "ID": 868,
                  "firstname": "Qiuhua",
                  "lastname": "Lin"
                }
              },
              {
                "ID": 6,
                "user": {
                  "ID": 865,
                  "firstname": "Nurul Elma",
                  "lastname": "Rahmat"
                }
              },
              {
                "ID": 8,
                "user": {
                  "ID": 903,
                  "firstname": "Siti Rohani",
                  "lastname": "Sudin"
                }
              },
              {
                "ID": 9,
                "user": {
                  "ID": 880,
                  "firstname": "Ping",
                  "lastname": "Xu"
                }
              }
            ]
          }
        },
      ]
    }
  }
}
 */

export const classDetailById = (state = {}, action) => {
  if (action.type === FETCH_CLASS_DETAIL) {
    const data = get(action, 'value.data.getAllClassInfo.data[0]');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, state, action.value);
  }

  if (action.type === CLEAR_CLASS_DETAIL) {
    return Object.assign({}, state, {
      data: {},
    });
  }
  return state;
};

export const pendingPromotionSignedChildren = (state = {}, action) => {
  if (action.type === FETCH_FORM2_COUNT) {
    const data = get(
      action,
      'value.data.getPendingForm2ESignatureChildren.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const pendingWithdrawalSignedChildren = (state = {}, action) => {
  if (action.type === FETCH_FORM3_COUNT) {
    const data = get(
      action,
      'value.data.getPendingWithdrawalForm3ESignatureChildren.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allProgramsType = (state = {}, action) => {
  if (action.type === FIND_ALL_PROGRAMS) {
    const data = get(action, 'value.data.findAllProgram.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const classActivities = (state = {}, action) => {
  if (action.type === FETCH_CLASS_ACTIVITIES) {
    const data = get(action, 'value.data.findClassActivities.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const classActivityTags = (state = {}, action) => {
  if (action.type === FETCH_CLASS_ACTIVITY_TAGS) {
    const data = get(action, 'value.data.searchClassActivityTag');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const classActivityStickers = (state = {}, action) => {
  if (action.type === FETCH_CLASS_ACTIVITY_STICKERS) {
    const data = get(action, 'value.data.getAllStickersByIDSchool.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const classResources = (state = {}, action) => {
  if (action.type === FETCH_CLASS_RESOURCES) {
    const data = get(action, 'value.data.findAllClassResources.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const learningGoalsOptionsList = (state = {}, action) => {
  if (action.type === FETCH_LEARNING_GOALS_OPTIONS_LIST) {
    const data = get(action, 'value.data.getLearningGoals.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const feeTierForChild = (state = {}, action) => {
  if (action.type === FETCH_FEE_TIER_FOR_CHILD) {
    const data = get(action, 'value.data.getFeeTierForChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const childHealthInfo = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CHILD_HEALTH_INFO:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CHILD_HEALTH_INFO_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CHILD_HEALTH_INFO_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case UPDATE_CHILD_HEALTH_INFO_REQUEST:
      return {
        ...state,
        ...action.value,
      };
    case UPDATE_CHILD_HEALTH_INFO_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case UPDATE_CHILD_HEALTH_INFO_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const applicableFeeTierForChild = (state = {}, action) => {
  if (action.type === FETCH_APPLICABLE_FEE_TIER_FOR_CHILD) {
    const data = get(action, 'value.data.getApplicableFeeTiersForChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const childFeeTierForChild = (state = {}, action) => {
  if (action.type === FETCH_CHILD_FEE_TIER_FOR_CHILD) {
    const data = get(action, 'value.data.getChildFeeTierForChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const upcomingFeeTierForChild = (state = {}, action) => {
  if (action.type === FETCH_UPCOMING_FEE_TIER_FOR_CHILD) {
    const data = get(action, 'value.data.getUpcomingChildFeeTierForChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const cssProgrammes = (state = {}, action) => {
  if (action.type === FETCH_CSS_PROGRAMMES) {
    const data = get(action, 'value.data.cssProgrammesByFkChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const cssPillarNames = (state = {}, action) => {
  if (action.type === FETCH_CSS_PROGRAMMES_PILLAR_NAMES) {
    const data = get(action, 'value.data.getCSSPillarNames.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, action.value);
  }
  return state;
};

export const marketingConsentByParent = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_MARKETING_CONSENT_BY_PARENT_REQUEST:
      return {
        ...state,
        ...action.value,
      };
    case GET_MARKETING_CONSENT_BY_PARENT_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case GET_MARKETING_CONSENT_BY_PARENT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default listAllClasses;
